	<template>
	<div>
		<div class="row">
			<div class="col-md-6 text-center">
				<div class="card bgGreen border-0 pointer pacific">
					<div class="card-body pointer">
						<div class="font-weight-bold">
							<div class="font-size-21">Ingreso por Préstamo</div>
							<div class="font-size-14">
								<div class="row">
									<div class="col-md-12 text-center">
										{{ numeral(localData.totalLoan).format('$0,0.00') }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6 text-center">
				<div class="card bgRls border-0 pointer rls">
					<div class="card-body pointer">
						<div class="font-weight-bold">
							<div class="font-size-21">Total Pagado</div>
							<div class="font-size-14">
								<div class="row">
									<div class="col-md-12 text-center">
										{{ numeral(localData.totalPayment).format('$0,0.00') }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12 text-right">
				<!-- <a-button @click="initModule">Carga</a-button> -->
				<a-button @click="onOpenModal" icon="plus">Realizar pago</a-button>
			</div>
			<div class="col-md-12 pt20">
				<a-table :columns="columns" :dataSource="localData.list">
					<div slot="description" slot-scope="record" class="text-uppercase text-left">
						{{ record.description }}
					</div>
					<div slot="amount" slot-scope="record" class="text-uppercase">
						{{ numeral(record.amount).format('$0,0') }}
					</div>
					<div slot="created_at" slot-scope="record" class="text-uppercase">
						{{ moment(record.created_at).format('DD-MM-YYYY') }}
					</div>
					<div slot="file" slot-scope="record" class="text-uppercase">
						<button class="btn btn-info ant-btn-sm ant-btn-icon-only ml7" @click="showFile(record.file_id)" v-if="record.file_id">
							<a-icon type="file-pdf" />
						</button>
					</div>
				</a-table>
			</div>
		</div>
		<a-modal width="50%" :visible="modal.visible" title="Abono a Préstamo" :closable="true" @cancel="onCloseModal">
			<template slot="footer">
				<a-button key="back" @click="onCloseModal"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="onSaveRecord"> Guardar </a-button>
			</template>
			<div>
				<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
					<div class="row">
						<div class="col-md-6">
							Archivo
							<br />
							<a-upload name="file" accept="application/pdf,image/*" :file-list="file" :remove="handleRemove" :before-upload="beforeUpload">
								<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
							</a-upload>
						</div>
						<div class="col-md-6">
							Monto del Abono
							<a-input prefix="$" autocomplete="off" v-model="modal.amount" />
						</div>
					</div>
				</a-spin>
			</div>
		</a-modal>
		<a-modal width="65%" :visible="modalFileVisible" :footer="null" title="Archivo" :closable="true" @cancel="handleCancelModalFile">
			<div class="row">
				<div class="col-12 text-center">
					<fileViewerComponent :fileURL="fileURL" v-if="modalFileVisible" />
				</div>
				<div class="col-12 text-right">
					<hr class="hrDotted" />
					<a class="ml5 btn btn-primary" :href="fileURL" target="new">Descargar archivo</a>
				</div>
			</div>
		</a-modal>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'
import numeral from 'numeral'
import moment from 'moment-timezone'
moment.tz.setDefault('America/Mexico_City')
import fileViewerComponent from '@/components/fileViewer'

//
export default {
	name: 'loanControlComponent',
	components: {
		fileViewerComponent,
	},
	computed: {
		...mapGetters('loanControl', ['spinnerLoaderLabel', 'spinnerLoader']),
	},
	data() {
		return {
			modalFileVisible: false,
			file: [],
			localData: {},
			columns: [
				{
					title: '#',
					align: 'center',
					dataIndex: 'id',
					width: '10%',
				},
				{
					title: 'Descripción',
					align: 'center',
					scopedSlots: { customRender: 'description' },
				},
				{
					title: 'Importe',
					align: 'center',
					scopedSlots: { customRender: 'amount' },
					width: '10%',
				},
				{
					title: 'Fecha',
					align: 'center',
					scopedSlots: { customRender: 'created_at' },
					width: '10%',
				},
				{
					title: 'Archivo',
					align: 'center',
					scopedSlots: { customRender: 'file' },
					width: '10%',
				},
			],
			modal: {
				visible: false,
				amount: '',
			},
			fileURL: '',
		}
	},
	mounted() {
		this.initModule()
	},
	methods: {
		numeral,
		moment,
		initModule() {
			this.$store.dispatch('loanControl/GET').then((response) => {
				this.localData = _.cloneDeep(utilities.objectValidate(response, 'data', {}))
			})
		},
		onOpenModal() {
			this.modal.visible = true
		},
		onCloseModal() {
			this.modal.visible = false
		},
		handleRemove() {
			this.cleanData()
		},
		beforeUpload(file) {
			this.file = [file]
			return false
		},
		cleanData() {
			this.modal = {
				visible: false,
				amount: '',
			}
			this.file = []
		},
		async onSaveRecord() {
			//
			let errors = []

			if (this.file.length == 0) {
				errors.push(`- Selecciona el archivo de evidencia.`)
			}
			if (this.modal.amount == '') {
				errors.push(`- Ingresa monto del abono`)
			}

			if (errors.length) {
				Swal.fire({
					title: 'Atención',
					html: errors.join('<br>'),
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
				return false
			}
			//

			let formData = new FormData()
			formData.append('file', this.file[0])
			formData.append('amount', this.modal.amount)

			// Subimos el archivo
			await this.$store
				.dispatch('loanControl/UPLOAD', formData)
				.then((response) => {
					// console.log('loanControl/UPLOAD::response-->', response)
					Swal.fire({
						title: 'Atención',
						html: 'Archivo subido de manera correcta',
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						this.cleanData()
						this.initModule()
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Atención',
						html: 'Error al subir el archivo',
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
		},
		handleCancelModalFile() {
			this.fileURL = ''
			this.modalFileVisible = false
		},
		showFile(id, action = 'show') {
			this.$store.dispatch('loanControl/GET_FILE_URL', { id, action }).then((response) => {
				// console.log('response-->', response)
				this.fileURL = response.fileURL
				setTimeout(() => {
					this.modalFileVisible = true
				}, 10)
			})
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>