<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="row">
				<div class="col-md-12 text-right">
					<a-button class="btn btn-warning" icon="container" @click="addFolder">Añadir carpeta</a-button>
					<a-button class="btn btn-info ml7" icon="file-add" @click="addFile"></a-button>
					<a-button class="btn btn-success ml7" icon="reload" @click="getFiles(null)" />
				</div>
			</div>
			<hr />
			<a-breadcrumb>
				<a-breadcrumb-item><a href="#" @click="getFiles(null)">Inicio</a></a-breadcrumb-item>
				<a-breadcrumb-item v-for="(element, index) in breadcrumbs" :key="index">
					<span @click="getFiles(element.parent_id, element.name)">{{ element.name }}</span>
				</a-breadcrumb-item>
			</a-breadcrumb>
			<hr />
			<div class="row" v-if="breadcrumbs.length">
				<div class="col-md-12">
					<span @click="getLastBreadcrumb" class="pointer" style="font-size: 16px">|..</span>
				</div>
			</div>
			<div v-if="filesList.length">
				<div class="row" v-for="(element, index) in filesList" :key="index" style="font-size: 16px">
					<div class="col-md-9">
						<span @click="element.type == 'file' ? showFile(element.id) : getFiles(element.id, element.name)" class="pointer"><a-icon :type="element.type" /> {{ element.name }}</span>
					</div>
					<div class="col-md-3 text-right">{{ moment(element.created_at).format('DD/MM/YYYY HH:mm') }} hrs.</div>
				</div>
			</div>
			<div class="row" v-else>
				<div class="col-md-12">Folder vacío</div>
			</div>
		</a-spin>
		<a-modal width="50%" :visible="modal.visible" title="Archivo Digital" :closable="true" @cancel="onCloseModal">
			<template slot="footer">
				<a-button key="back" @click="onCloseModal"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="onSaveFile"> Guardar </a-button>
			</template>
			<uploadFile ref="uploadFile" :parentID="actualNode.parent_id" @ok="onCloseModal()" />
		</a-modal>
		<a-modal width="65%" :visible="modalFileVisible" :footer="null" title="Archivo" :closable="true" @cancel="handleCancelModalFile">
			<div class="row">
				<div class="col-12 text-center">
					<fileViewerComponent :fileURL="fileURL" v-if="modalFileVisible" />
				</div>
				<div class="col-12 text-right">
					<hr class="hrDotted" />
					<a class="ml5 btn btn-primary" :href="fileURL" target="new">Descargar archivo</a>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'
import _ from 'lodash'
import Swal from 'sweetalert2'
import moment from 'moment-timezone'
moment.tz.setDefault('America/Mexico_City')
import uploadFile from './uploadFile.vue'
import fileViewerComponent from '@/components/fileViewer'

export default {
	name: 'fileManager',
	components: {
		uploadFile,
		fileViewerComponent,
	},
	computed: {
		...mapGetters('fileManager', ['spinnerLoaderLabel', 'spinnerLoader']),
	},
	data: () => {
		return {
			filesList: [],
			actualNode: {
				parent_id: null,
				name: '',
			},
			breadcrumbs: [],
			modal: {
				visible: false,
			},
			fileURL: '',
			modalFileVisible: false,
		}
	},
	mounted() {
		this.getFiles(null)
	},
	methods: {
		moment,
		getLastBreadcrumb() {
			if (this.breadcrumbs.length > 1) {
				let lastItem = this.breadcrumbs[this.breadcrumbs.length - 2]
				this.getFiles(lastItem.parent_id, lastItem.name)
			} else {
				this.getFiles(null)
			}
		},
		getFiles(parent_id = null, name = '') {
			// validamos que el parent_id sea diferente al actual
			if (this.actualNode.parent_id != null && this.actualNode.parent_id == parent_id) {
				return false
			}

			// Ingresamos el parent_id y el nombre de la carpeta al objeto de breadcrumb
			if (parent_id == null) {
				this.breadcrumbs = []
			} else {
				// Validamos si es que existe el nodo en breadcrumbs
				if (this.breadcrumbs.find((e) => e.parent_id == parent_id)) {
					let newBreadcrumbs = []
					let continueLoop = true
					this.breadcrumbs.forEach((e) => {
						if (continueLoop) {
							newBreadcrumbs.push(e)
						}
						if (e.parent_id == parent_id) {
							continueLoop = false
						}
					})
					this.breadcrumbs = _.cloneDeep(newBreadcrumbs)
				} else {
					this.breadcrumbs.push({
						name,
						parent_id,
					})
				}
			}

			this.actualNode = {
				name,
				parent_id,
			}

			// Cargamos los elementos conforme al parent_id
			this.$store.dispatch('fileManager/GET', { parentID: parent_id }).then((response) => {
				this.filesList = _.cloneDeep(response.data)
			})
		},
		addFolder() {
			Swal.fire({
				title: 'Archivo digital',
				text: 'Ingresa nombre para la carpeta',
				input: 'text',
				showCancelButton: true,
				confirmButtonText: 'Continuar',
				cancelButtonText: 'Cancelar',
				confirmButtonColor: '#41b883',
				cancelButtonColor: '#f5222e',
				reverseButtons: true,
				inputAttributes: {
					autocorrect: 'off',
					autocomplete: 'off',
				},
				inputValidator: (value) => {
					return new Promise((resolve) => {
						if (!value) {
							resolve('Ingresa nombre para la carpeta')
						}
						resolve()
					})
				},
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store
						.dispatch('fileManager/CREATE_FOLDER', {
							parentID: this.actualNode.parent_id,
							name: result.value,
							type: 'folder',
						})
						.then(() => {
							this.getFiles(this.parentID, '')
						})
				}
			})
		},
		addFile() {
			this.modal.visible = true
		},
		onCloseModal() {
			this.modal.visible = false
			this.$refs.uploadFile.cleanData()
		},
		onSaveFile() {
			this.$refs.uploadFile.onSaveRecord()
		},
		handleCancelModalFile() {
			this.fileURL = ''
			this.modalFileVisible = false
		},
		showFile(id, action = 'show') {
			this.$store.dispatch('fileManager/GET_FILE_URL', { id, action }).then((response) => {
				// console.log('response-->', response)
				this.fileURL = response.fileURL
				setTimeout(() => {
					this.modalFileVisible = true
				}, 10)
			})
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>