<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="row">
				<div class="col-md-6">
					Archivo
					<br />
					<a-upload name="file" :file-list="file" :remove="handleRemove" :before-upload="beforeUpload" @change="handleChange">
						<a-button> <a-icon type="upload" /> Seleccionar archivo </a-button>
					</a-upload>
				</div>
				<div class="col-md-6">
					Nombre del archivo
					<a-input autocomplete="off" v-model="payload.name" />
				</div>
			</div>
			<div class="row pt10">
				<div class="col-md-12">
					Descripción
					<a-textarea rows="4" autocomplete="off" v-model="payload.description" />
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
import utilities from '@/services/utilities'
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
//
export default {
	name: 'uploadFileComponent',
	props: {
		parentID: {
			default: null,
		},
	},
	computed: {
		...mapGetters('fileManager', ['spinnerLoaderLabel', 'spinnerLoader']),
	},
	data() {
		return {
			file: [],
			payload: {
				name: '',
				description: '',
			},
		}
	},
	methods: {
		handleRemove() {
			this.cleanData()
		},
		beforeUpload(file) {
			this.file = [file]
			return false
		},
		handleChange(info) {
			if (info.file.status !== 'uploading') {
				// console.log(info.file)
				this.payload.name = utilities.objectValidate(info, 'file.name', '')
			}
		},
		cleanData() {
			this.file = []
			this.payload = {
				name: '',
				description: '',
			}
		},
		async onSaveRecord() {
			//
			let errors = []

			if (this.payload.name == '') {
				errors.push(`- Ingresa nombre del archivo a subir`)
			}
			if (this.file.length == 0) {
				errors.push(`- Ingresa el archivo.`)
			}

			if (errors.length) {
				Swal.fire({
					title: 'Atención',
					html: errors.join('<br>'),
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
				return false
			}
			//

			let formData = new FormData()
			formData.append('file', this.file[0])
			formData.append('name', this.payload.name)
			formData.append('parentID', this.parentID)
			formData.append('description', this.payload.description)

			// Subimos el archivo
			await this.$store
				.dispatch('fileManager/UPLOAD', formData)
				.then((response) => {
					// console.log('fileManager/UPLOAD::response-->', response)
					Swal.fire({
						title: 'Atención',
						html: 'Archivo subido de manera correcta',
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						this.cleanData()
						this.$emit('ok')
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Atención',
						html: 'Error al subir el archivo',
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
		},
	},
}
</script>
