<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="row">
				<div class="col-md-12 text-right">
					<a-button class="btn btn-info" icon="plus" @click="onAddRecord()">Añadir</a-button>
					<a-button class="btn btn-success ml7" icon="reload" @click="onReload()" />
				</div>
			</div>
			<div class="row pt10">
				<div class="col-md-12">
					<a-table :columns="columns" :dataSource="usersList" rowKey="user_id">
						<div slot="action" slot-scope="record">
							<a-button type="info" size="small" icon="edit" @click="onEditRecord(record.user_id)" />
							<a-button class="ml7" size="small" :icon="record.status ? 'minus-circle' : 'plus-circle'" @click="onDisableRecord(record.user_id, record.status)" />
						</div>
						<div slot="lastSurveyDate" slot-scope="record">
							{{ record.lastSurveyDate ? record.lastSurveyDate : '----' }}
						</div>
					</a-table>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import _ from 'lodash'

export default {
	name: 'Users',
	data() {
		return {
			columns: [
				{
					title: 'ID',
					dataIndex: 'user_id',
					align: 'center',
				},
				{
					title: 'Nombre completo',
					dataIndex: 'fullName',
				},
				{
					title: 'Email',
					dataIndex: 'email',
				},
				{
					title: 'Teléfono',
					dataIndex: 'mobile_number',
					align: 'center',
				},
				{
					title: 'Última evaluación',
					scopedSlots: { customRender: 'lastSurveyDate' },
					align: 'center',
				},
			],
		}
	},
	computed: {
		...mapGetters('user', ['user']),
		...mapGetters('users', ['usersList']),
		spinnerLoaderLabel() {
			return this.$store.state.users.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.users.spinnerLoader
		},
		isUserAdmin() {
			return this.user.is_admin ? true : false
		},
	},
	beforeMount() {
		let allowedUserPermition = [31]
		if (this.isUserAdmin || !!allowedUserPermition.includes(this.user.user_id)) {
			this.columns.push({
				title: 'Acciones',
				scopedSlots: { customRender: 'action' },
				align: 'right',
			})
		}
	},

	mounted() {
		if (this.usersList.length == 0) {
			this.initModule()
		}
	},

	methods: {
		initModule() {
			this.$store.dispatch('users/GET')
		},
		onReload() {
			this.initModule()
		},
		onAddRecord() {
			this.$router.push('/management/collaborators/create')
		},
		onEditRecord(id) {
			this.$store.dispatch('users/GET_ONE', id).then(() => {
				this.$router.push(`/management/collaborators/${id}`)
			})
		},
		onDisableRecord(id, status) {
			Swal.fire({
				title: 'Atención',
				text: `¿Desea ${status ? 'deshabilitar' : 'habilitar'} el registro?`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					if (status) {
						this.$store.dispatch('users/DISABLE', id)
					} else {
						this.$store.dispatch('users/ENABLE', id)
					}
				}
			})
		},
	},
}
</script>